
export const OrdersHeaders = {
  columns: [
    {
      key: "orderTXNID",
      label: "Order TXN ID",
      dataCellStyles: {
        wordBreak: "break-all",
        "& span": {
          width: 100,
          display: "block",
        },
      },
    },
    {
      key: "orderNumber",
      label: "Venue order no",
    },
    {
      key: "customerId",
      label: "Client ID",
      isLink: true,
    },
    {
      key: "clientName",
      label: "Name",
      isLink: true,
      dataCellStyles: {
        width: 200,
      },
    },
    {
      key: "venueAcctNumber",
      label: "Venue acct no",
    },
    {
      key: "orderType",
      label: "Order type",
    },
    {
      key: "side",
      label: "Side",
    },
    {
      key: "symbol",
      label: "Symbol",
    },
    {
      key: "amountCash",
      label: "Amount cash",
    },
    {
      key: "quantity",
      label: "Qty",
    },
    {
      key: "netPosition",
      label: "Net position",
    },
    {
      key: "averageprice",
      label: "Avg price",
    },
    {
      key: "totalAmount",
      label: "Total amount",
    },
    {
      key: "orderStatus",
      label: "Order status",
    },
    {
      key: "statusmessage",
      label: "Status",
    },
    {
      key: "priceAtRequest",
      label: "Price at request",
    },
    {
      key: "entryDate",
      label: "Entry date",
      withTimestamp: true,
    },
    {
      key: "createdDate",
      label: "Created date",
      withTimestamp: true,
    },
    {
      key: "updateddate",
      label: "Updated date",
      withTimestamp: true,
    },
    {
      key: "apiRequestId",
      label: "API request ID",
    },
  ],
  dateColumns: ["entryDate", "createdDate", "updateddate"],
  searchColumns: ["orderTXNID", "orderNumber", "customerId", "clientName"],
  ellipseColumns: ["statusmessage"],
  equitiesFilters: ["NEW", "PARTIAL_FILL", "CANCELED", "REJECTED", "FILLED"],
  cryptoFilters: ["WORKING", "FILLED", "CANCELED", "REJECTED"],
  cfdFilters: ["WORKING", "FILLED", "CANCELED", "REJECTED"],
};