import { processTransactionStatus, processTransactionStatusData, processPaymentProviderName, processTransactionType, processTransactionCurrency, processReportIdName, processManualReviewStatus, processPaymentMethod, processGiftStatus, processAmountWithDecimals, processReferralReason, processReferralApproval, processGiftId } from 'utils';

export const TransactionsHeaders = {
  columns: [
    {
      key: 'txnNumber',
      label: 'Transaction ID',
      dataCellStyles: {
        wordBreak: 'break-all',
        '& span': {
          width: 130,
          display: 'block'
        }
      }
    },
    {
      key: 'txnCreatedAt',
      label: 'Transaction date',
      withTimestamp: true,
      dataCellStyles: {
        width: 155
      }
    },
    {
      key: 'txnStatusLastUpdatedAt',
      label: 'Status updated',
      withTimestamp: true,
      dataCellStyles: {
        width: 155
      }
    },
    {
      key: 'amountInUSD',
      label: 'USD amount'
    },
    {
      key: 'paymentMethod',
      label: 'Method',
      dataCellStyles: {
        width: 100
      },
      processCellData: processPaymentMethod
    },
    {
      key: 'paymentProviderName',
      label: 'Provider',
      processCellData: processPaymentProviderName,
      dataCellStyles: {
        width: 100
      }
    },
    {
      key: 'clientId',
      label: 'Client ID',
      isLink: true
    },
    {
      key: 'clientName',
      label: 'Name',
      isLink: true,
      dataCellStyles: {
        width: 200
      }
    },
    {
      key: 'status',
      label: 'Status'
    }
  ],
  sortableColumns: ['txnNumber', 'txnCreatedAt', 'txnStatusLastUpdatedAt', 'type', 'paymentProviderName'],
  statusData: [
    {
      key: 'status',
      type: 'oval',
      valid: ['Failed|ALERT', 'Reconciled|VERIFIED', 'Unreceived|ALERT', 'Pending|WARNING', 'Successful|VERIFIED', 'Credited|VERIFIED', 'Credit error|ALERT', 'Requested|REVIEW', 'DW Cleared|ALERT', 'DW Cleared >|ALERT', 'DW Rejected|ALERT', 'Queued|WARNING', 'Queued >|WARNING', 'Approved|VERIFIED', 'Pending review|WARNING','Pending >|WARNING'],
      processStatus: processTransactionStatus,
      processData: processTransactionStatusData
    }
  ],
  statusActions: ['WITHDRAWAL_REQUESTED', 'QUEUED', 'AWAITING_FINOPS_APPROVAL'],
  reviewStatusActions: ['REVIEW_REQUIRED'],
  dateColumns: ['txnCreatedAt', 'txnStatusLastUpdatedAt'],
  finOpsFilters: ['WITHDRAWAL_REQUESTED', 'WITHDRAWAL_REQUEST_INIT', 'QUEUED'],
  finOpsWithdrawFilters: ['WITHDRAWAL_REQUESTED', 'WITHDRAWAL_REQUEST_INIT', 'QUEUED'],
  depositFilters: ['APPROVED_BY_PAYMENT_PROVIDER', 'FAILED', 'RECONCILED', 'UNRECEIVED', 'SUCCESSFUL', 'CREDIT_ERROR', 'DEPOSIT', 'WITHDRAW'],
  withdrawFilters: ['WITHDRAWAL_REQUESTED', 'WITHDRAWAL_REQUEST_INIT', 'DW_CLEARED', 'DW_REJECTED', 'QUEUED', 'SUCCESSFUL', 'DEPOSIT', 'WITHDRAW'],
  transactionFilters: ['APPROVED_BY_PAYMENT_PROVIDER', 'FAILED', 'RECONCILED', 'UNRECEIVED', 'SUCCESSFUL', 'CREDIT_ERROR', 'WITHDRAWAL_REQUESTED', 'WITHDRAWAL_REQUEST_INIT', 'DW_CLEARED', 'DW_REJECTED', 'QUEUED', 'WITHDRAW', 'DEPOSIT'],
  searchColumns: ['txnNumber', 'clientId', 'clientName']
};

export const TransactionsWithdrawalHeaders = {
  columns: [
    {
      key: "txnNumber",
      label: "Transaction ID",
      dataCellStyles: {
        wordBreak: "break-all",
        "& span": {
          width: 130,
          display: "block",
        },
      },
    },
    {
      key: "txnCreatedAt",
      label: "Transaction date",
      withTimestamp: true,
      dataCellStyles: {
        width: 155,
      },
    },
    {
      key: "txnStatusLastUpdatedAt",
      label: "Status updated",
      withTimestamp: true,
      dataCellStyles: {
        width: 155,
      },
    },
    {
      key: "batchNo",
      label: "Report ID",
      dataCellStyles: {
        wordBreak: "break-all",
        width: 120,
        "& span": {
          width: 90,
          display: "block",
        },
      },
      processCellData: processReportIdName,
    },
    {
      key: "currency",
      label: "Local amount",
      processCellData: processTransactionCurrency,
    },
    {
      key: "amountInUSD",
      label: "USD amount",
    },
    {
      key: "clientId",
      label: "Client ID",
      isLink: true,
    },
    {
      key: "clientName",
      label: "Name",
      isLink: true,
      dataCellStyles: {
        width: 200,
      },
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "bankAccountStatus",
      label: "Manual review",
    },
  ],
  sortableColumns: [
    "txnNumber",
    "txnCreatedAt",
    "txnStatusLastUpdatedAt",
    "type",
    "paymentProviderName",
  ],
  statusData: [
    {
      key: "status",
      type: "oval",
      valid: [
        "Failed|ALERT",
        "Reconciled|VERIFIED",
        "Unreceived|ALERT",
        "Pending|WARNING",
        "Successful|VERIFIED",
        "Credited|VERIFIED",
        "Credit error|ALERT",
        "Requested|REVIEW",
        "Requested >|REVIEW",
        "DW Cleared|ALERT",
        "DW Cleared >|ALERT",
        "DW Rejected|ALERT",
        "Queued|WARNING",
        "Queued >|WARNING",
        "Approved|VERIFIED",
        "Pending review|WARNING",
        "Cancelled|ALERT",
        "Rejected|ALERT",
        "Declined|ALERT",
      ],
      processStatus: processTransactionStatus,
      processData: processTransactionStatusData,
    },
    {
      key: "bankAccountStatus",
      type: "oval",
      valid: ["Approved", "Rejected|ALERT", "Name check >|WARNING"],
      processStatus: processManualReviewStatus,
    },
  ],
  statusActions: ["QUEUED", "WITHDRAWAL_REQUESTED"],
  reviewStatusActions: ["REVIEW_REQUIRED"],
  dateColumns: ["txnCreatedAt", "txnStatusLastUpdatedAt"],
  finOpsFilters: ["WITHDRAWAL_REQUESTED", "WITHDRAWAL_REQUEST_INIT", "QUEUED"],
  finOpsWithdrawFilters: [
    "WITHDRAWAL_REQUESTED",
    "WITHDRAWAL_REQUEST_INIT",
    "QUEUED",
    "CANCELLED",
  ],
  depositFilters: [
    "APPROVED_BY_PAYMENT_PROVIDER",
    "FAILED",
    "RECONCILED",
    "UNRECEIVED",
    "SUCCESSFUL",
    "CREDIT_ERROR",
    "DEPOSIT",
    "WITHDRAW",
  ],
  withdrawFilters: [
    "WITHDRAWAL_REQUESTED",
    "WITHDRAWAL_REQUEST_INIT",
    "DW_CLEARED",
    "DW_REJECTED",
    "QUEUED",
    "SUCCESSFUL",
    "DEPOSIT",
    "WITHDRAW",
    "CANCELLED",
    "DECLINED",
    "REJECTED",
  ],
  transactionFilters: [
    "APPROVED_BY_PAYMENT_PROVIDER",
    "FAILED",
    "RECONCILED",
    "UNRECEIVED",
    "SUCCESSFUL",
    "CREDIT_ERROR",
    "WITHDRAWAL_REQUESTED",
    "WITHDRAWAL_REQUEST_INIT",
    "DW_CLEARED",
    "DW_REJECTED",
    "QUEUED",
    "WITHDRAW",
    "DEPOSIT",
  ],
  searchColumns: ["txnNumber", "clientId", "clientName"],
  actions: [
    {
      key: "COMMENT",
      action: () => {},
    },
  ],
};

export const TransactionsGiftsHeaders = {
  columns: [
    {
      key: "impactRewardId",
      label: "Gift ID",
      dataCellStyles: {
        width: 100,
      },
      isEllipseColumnWithCopy: true,
      processCellData: processGiftId,
    },
    {
      key: "rewardTxnNumber",
      label: "Transaction ID",
      dataCellStyles: {
        width: 100,
      },
      isEllipseColumnWithCopy: true,
      processCellData: processGiftId,
    },
    {
      key: "createdAt",
      label: "Created date",
      withTimestamp: true,
      dataCellStyles: {
        width: 155,
      },
    },
    {
      key: "txnStatusLastUpdatedAt",
      label: "Status updated",
      withTimestamp: true,
      dataCellStyles: {
        width: 155,
      },
    },
    {
      key: "amountInUSD",
      label: "USD amt",
      processCellData: processAmountWithDecimals,
    },
    {
      key: "customerId",
      label: "Client ID",
      isLink: true,
    },
    {
      key: "name",
      label: "Name",
      isLink: true,
      dataCellStyles: {
        width: 200,
      },
    },
    {
      key: "totalApprovedGifts",
      label: "Approval",
      processCellData: processReferralApproval,
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "rewardType",
      label: "Reason",
      processCellData: processReferralReason,
    },
  ],
  sortableColumns: ["impactRewardId", "createdAt", "txnStatusLastUpdatedAt"],
  statusData: [
    {
      key: "status",
      type: "oval",
      valid: [
        "Failed|ALERT",
        "Pending >|WARNING",
        "Rejected|ALERT",
        "Approved|VERIFIED",
      ],
      processStatus: processGiftStatus,
    },
  ],
  statusActions: ["PENDING"],
  dateColumns: ["createdAt", "txnStatusLastUpdatedAt"],
  searchColumns: ["impactRewardId", "txnNumber", "customerId", "name"],
  actions: [
    {
      key: "COMMENT",
      action: () => {},
    },
  ],
};

export const FinopsTransactionsWithdrawalHeaders = {
  columns: [
    {
      key: 'txnNumber',
      label: 'Transaction ID',
      dataCellStyles: {
        wordBreak: 'break-all',
        '& span': {
          width: 130,
          display: 'block'
        }
      }
    },
    {
      key: 'txnCreatedAt',
      label: 'Transaction date',
      withTimestamp: true,
      dataCellStyles: {
        width: 155
      }
    },
    {
      key: 'txnStatusLastUpdatedAt',
      label: 'Status updated',
      withTimestamp: true,
      dataCellStyles: {
        width: 155
      }
    },
    {
      key: 'type',
      label: 'Type',
      processCellData: processTransactionType
    },
    {
      key: 'batchNo',
      label: 'Report ID',
      dataCellStyles: {
        wordBreak: 'break-all',
        width: 120,
        '& span': {
          width: 90,
          display: 'block'
        }
      },
      processCellData: processReportIdName,
    },
    {
      key: 'currency',
      label: 'Local amount',
      processCellData: processTransactionCurrency
    },
    {
      key: 'amountInUSD',
      label: 'USD amount'
    },
    {
      key: 'paymentMethod',
      label: 'Method',
      dataCellStyles: {
        width: 100
      },
      processCellData: processPaymentMethod
    },
    {
      key: 'paymentProviderName',
      label: 'Provider',
      processCellData: processPaymentProviderName,
      dataCellStyles: {
        width: 100
      }
    },
    {
      key: 'clientId',
      label: 'Client ID',
      isLink: true
    },
    {
      key: 'clientName',
      label: 'Name',
      isLink: true,
      dataCellStyles: {
        width: 200
      }
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'bankAccountStatus',
      label: 'Manual review'
    }
  ],
  sortableColumns: ['txnNumber', 'txnCreatedAt', 'txnStatusLastUpdatedAt', 'type', 'paymentProviderName'],
  statusData: [
    {
      key: 'status',
      type: 'oval',
      valid: ['Failed|ALERT', 'Reconciled|VERIFIED', 'Unreceived|ALERT', 'Pending|WARNING', 'Successful|VERIFIED', 'Credited|VERIFIED', 'Credit error|ALERT', 'Requested|REVIEW', 'Requested >|REVIEW', 'DW Cleared|ALERT', 'DW Cleared >|ALERT', 'DW Rejected|ALERT', 'Queued|WARNING', 'Queued >|WARNING', 'Approved|VERIFIED', 'Pending review|WARNING'],
      processStatus: processTransactionStatus,
      processData: processTransactionStatusData
    },
    {
      key: 'bankAccountStatus',
      type: 'oval',
      isBlank: true,
      valid: ['Approved', 'Rejected|ALERT', 'Name check >|WARNING'],
      processStatus: processManualReviewStatus
    }
  ],
  statusActions: ['QUEUED', 'WITHDRAWAL_REQUESTED'],
  reviewStatusActions: ['REVIEW_REQUIRED'],
  dateColumns: ['txnCreatedAt', 'txnStatusLastUpdatedAt'],
  finOpsFilters: ['WITHDRAWAL_REQUESTED', 'WITHDRAWAL_REQUEST_INIT', 'QUEUED'],
  finOpsWithdrawFilters: ['WITHDRAWAL_REQUESTED', 'WITHDRAWAL_REQUEST_INIT', 'QUEUED'],
  depositFilters: ['APPROVED_BY_PAYMENT_PROVIDER', 'FAILED', 'RECONCILED', 'UNRECEIVED', 'SUCCESSFUL', 'CREDIT_ERROR', 'DEPOSIT', 'WITHDRAW'],
  withdrawFilters: ['WITHDRAWAL_REQUESTED', 'WITHDRAWAL_REQUEST_INIT', 'DW_CLEARED', 'DW_REJECTED', 'QUEUED', 'SUCCESSFUL', 'DEPOSIT', 'WITHDRAW'],
  transactionFilters: ['APPROVED_BY_PAYMENT_PROVIDER', 'FAILED', 'RECONCILED', 'UNRECEIVED', 'SUCCESSFUL', 'CREDIT_ERROR', 'WITHDRAWAL_REQUESTED', 'WITHDRAWAL_REQUEST_INIT', 'DW_CLEARED', 'DW_REJECTED', 'QUEUED', 'WITHDRAW', 'DEPOSIT'],
  searchColumns: ['txnNumber', 'clientId', 'clientName']
};