import { processCountryRestrictionStatus } from "utils";
export const CountriesHeaders = {
  columns: [
    {
      key: "countryName",
      label: "Country",
      pickValuesFrom: ["countryShortName2c"],
    },
    {
      key: "residency",
      label: "Residency",
      processCellData: processCountryRestrictionStatus,
    },
    {
      key: "nationality",
      label: "Nationality",
      processCellData: processCountryRestrictionStatus,
    },
    {
      key: "cfdTrading",
      label: "CFD trading",
      processCellData: processCountryRestrictionStatus,
    },
    {
      key: "cryptoCFDTrading",
      label: "Crypto trading",
      processCellData: processCountryRestrictionStatus,
    },
    {
      key: "leveragedCryptoCFDTrading",
      label: "Leveraged crypto CFD trading",
      processCellData: processCountryRestrictionStatus,
    },
    {
      key: "tax",
      label: "Tax",
      processCellData: processCountryRestrictionStatus,
    },
    {
      key: "withdrawal",
      label: "Withdrawal",
      processCellData: processCountryRestrictionStatus,
    },
  ],
  searchColumns: ["countryName"],
  sortableColumns: ["countryName"],
  dropdownList: [
    {
      value: "SUPPORTED",
      label: "Supported",
    },
    {
      value: "IOS_ONLY_RESTRICTED",
      label: "iOS-only restricted",
    },
    {
      value: "RESTRICTED",
      label: "Restricted",
    },
  ],
  countryColumns: ["countryName"],
  dropdownColumns: [
    "residency",
    "residency",
    "nationality",
    "cfdTrading",
    "cryptoCFDTrading",
    "leveragedCryptoCFDTrading",
    "tax",
    "withdrawal",
  ],
};