export const Permissions = [
  {
    module: "DASHBOARD",
    displayName: "Dashboard",
    permissions: "READ",
    isVisible: true,
    submodules: [
      {
        resourceName: "DASHBOARD_COMP",
        displayName: "Compliance",
        permissions: "READ",
        isVisible: false,
      },
      {
        resourceName: "DASHBOARD_ACC",
        displayName: "Account Opening",
        permissions: "READ",
        isVisible: false,
      },
      {
        resourceName: "DASHBOARD_FIN_OPS",
        displayName: "FinOps",
        permissions: "READ",
        isVisible: false,
      },
      // {
      //   resourceName: 'RECONCILIATION',
      //   displayName: 'Daily Reconciliation',
      //   permissions: 'READ',
      //   isVisible: false
      // }
    ],
  },
  {
    module: "CLIENTS",
    displayName: "Clients",
    permissions: "READ",
    isVisible: false,
  },
  {
    module: "PAYMENTS",
    displayName: "Payments",
    permissions: "READ",
    isVisible: false,
  },
  {
    module: "MARKETS",
    displayName: "Markets",
    permissions: "READ",
    isVisible: false,
  },
  {
    module: "ORDERS",
    displayName: "Orders",
    permissions: "READ",
    isVisible: false,
  },
  {
    module: "REPORTS",
    displayName: "Reports",
    permissions: "READ",
    isVisible: false,
  },
  {
    module: "EXCHANGE_HOLIDAY",
    displayName: "Exchange",
    permissions: "READ",
    isVisible: false,
  },
  {
    module: "COUNTRIES",
    displayName: "Countries",
    permissions: "READ",
    isVisible: false,
  },
  {
    module: "ROLES_PERMISSION",
    displayName: "Roles & Permissions",
    permissions: "READ",
    isVisible: false,
  },
];