/* eslint-disable array-callback-return */
import moment from 'moment';
import _ from 'lodash';

export const isObject = (o: any) => {
  if (typeof o === 'object') {
    return true;
  }
  return false;
};

/**
  Table Sorting methods
 */
export const descendingComparator = (a: any, b: any, orderBy: any) => {
  if (isObject(a) && isObject(b)) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export type Order = 'asc' | 'desc';

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = <T>(array: readonly T[], comparator: (a: T, b: T) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const parseQueryString = (url: string) => {
  const queryString = url.replace(/.*\?/, '');

  if (queryString === url || !queryString) {
    return null;
  }

  const urlParams = new URLSearchParams(queryString);
  const result: any = {};

  urlParams.forEach((val, key) => {
    if (result.hasOwnProperty(key)) {
      result[key] = [result[key], val];
    } else {
      result[key] = val;
    }
  });

  return result;
};

export const processRiskRating = (riskRating: number, returnClass?: boolean) => {
  if (riskRating >= 0 && riskRating <= 12) {
    return returnClass ? 'Low|VERIFIED' : 'Low';
  } else if (riskRating > 12 && riskRating <= 17) {
    return returnClass ? 'Medium|REVIEW' : 'Medium';
  } else if (riskRating > 17 && riskRating <= 22) {
    return returnClass ? 'High|WARNING' : 'High';
  } else if (riskRating > 22 && riskRating <= 27) {
    return returnClass ? 'Very high|ALERT' : 'Very high';
  }
};

export const processScreening = (screening: string, returnClass?: boolean) => {
  if (screening === 'false_positive') {
    return returnClass ? 'No adverse matches|VERIFIED' : 'No adverse matches';
  } else if (screening === 'potential_match') {
    return returnClass ? 'Possible adverse matches|WARNING' : 'Possible adverse matches';
  } else if (screening === 'true_positive') {
    return returnClass ? 'Definite adverse matches|ALERT' : 'Definite adverse matches';
  } else if (screening === 'no_match' || !screening) {
    return returnClass ? 'No match|ALERT' : 'No match';
  } else if (screening === 'unknown') {
    return 'Unknown';
  } 
};

export const processAppropriateTest = (test: string, returnClass?: boolean) => {
  if (test === 'PASS') {
    return returnClass ? 'Pass|VERIFIED' : 'Pass';
  } else if (test === 'FAIL') {
    return returnClass ? 'Fail|ALERT' : 'Fail';
  }
};

export const processKycStatus = (type: string, kyc: string | number) => {
  if (type === 'SCREENING') {
    return processScreening(kyc as string, true);
  } else if (type === 'RISK_RATING') {
    return processRiskRating(kyc as number, true);
  } else if (type === 'APPROPRIATE_TEST') {
    return processAppropriateTest(kyc as string, true);
  }
};

export const processIdVerification = (idVerification: string, returnClass?: boolean, from?: string) => {
  if (idVerification === 'RESUBMISSION_REQUIRED') {
    return 'Resubmission required';
  } else if (idVerification === 'VERIFIED') {
    return 'Verified';
  } else if (idVerification === 'MANUAL_REVIEW_REQUIRED') {
    return 'Manual review required';
  } else if (idVerification === 'REJECTED' && from === 'DASHBOARD') {
    return 'Rejected >';
  }  else if (idVerification === 'REJECTED') {
    return 'Rejected';
  } else if (idVerification === 'PENDING') {
    return 'Pending';
  } else if (idVerification === 'DIS_QUALIFIED') {
    return 'Disqualified';
  }
};

export const processTransactionStatus = (status: string) => {
  const statusActions = ['QUEUED', 'WITHDRAWAL_REQUESTED'];
  if (status === 'ACCEPTED_BY_BANK' || status === 'PENDING_WITH_BANK' || status === 'APPROVED_BY_PAYMENT_PROVIDER' || status === 'APPROVED') {
    return 'Approved';
  } else if (status === 'FAILED') {
    return 'Failed';
  } else if (status === 'RECONCILED') {
    return 'Reconciled';
  } else if (status === 'UNRECONCILED') {
    return 'Unreconciled';
  } else if (status === 'UNRECEIVED') {
    return 'Unreceived';
  } else if (status === 'CREDITED' || status === 'SUCCESSFUL' || status === 'PENDING') {
    return 'Credited';
  } else if (status === 'CREDIT_ERROR') {
    return 'Credit error';
  } else if (status === 'DW_PENDING' || status === 'DW_APPROVED') {
    return 'Requested';
  } else if (status === 'DW_CLEARED') {
    if (statusActions.includes(status)) {
      return `DW cleared >`;
    }
    return 'DW cleared';
  } else if (status === 'DW_REJECTED') {
    return 'DW rejected';
  } else if (status === 'QUEUED') {
    if (statusActions.includes(status)) {
      return `Queued >`;
    }
    return 'Queued';
  } else if (status === 'WITHDRAWAL_REQUESTED') {
    if (statusActions.includes(status)) {
      return `Requested >`;
    }
    return 'Requested';
  } else if (status === 'WITHDRAWAL_REQUEST_INIT') {
    return 'Pending review';
  } else if (status === 'CANCELLED') {
    return 'Cancelled';
  } else if (status === 'DECLINED') {
    return 'Declined';
  } else if (status === 'REJECTED') {
    return 'Rejected';
  } else if (status === 'AWAITING_FINOPS_APPROVAL'){
      return 'Pending >';
    }
};

export const processTransfersStatus = (status: string) => {
  if (status === 'SUCCESSFUL') {
    return 'Successful';
  } else if (status === 'REQUEST_FAILED') {
    return 'Failed';
  } else if (status === 'APPROVED') {
    return 'Credited';
  } else if (status === 'CREDIT_ERROR') {
    return 'Credit error';
  } else if (status === 'PENDING' || status === 'DW_PENDING' || status === 'TRANSFER_INIT') {
    return 'Requested';
  } else if (status === 'DW_CLEARED') {
    return 'DW cleared';
  } else if (status === 'DW_REJECTED') {
    return 'DW rejected';
  } else if (status === 'DW_APPROVED') {
    return 'Approved';
  }
};

export const processGiftStatus = (status: string) => {
  if (status === 'FAILED') {
    return 'Failed';
  } else if (status === 'APPROVED') {
    return 'Approved';
  } else if (status === 'PENDING') {
    return 'Pending >';
  } else if (status === 'REJECTED') {
    return 'Rejected';
  }
};

export const processSourceDestination = (value: string) => {
  if (value === 'EQUITY_DRIVEWEALTH') {
    return 'Cash equities';
  } else if (value === 'WALLET') {
    return 'Wallet';
  } else if (value === 'CFD_METATRADER5') {
    return 'CFD';
  } else if (value === 'CRYPTO_METATRADER5') {
    return 'Crypto';
  }
};

export const processPaymentMethod = (method: string) => {
  if (method === 'APPLE_PAY') {
    return 'Apple pay';
  } else if (method === 'BANK_TRANSFER') {
    return 'Bank transfer';
  } else if (method === 'CARD_TRANSFER') {
    return 'Card transfer';
  } else if (method === 'GOOGLE_PAY') {
    return 'Google pay';
  }
};

export const processTransactionStatusData = (o: any, from: string) => {
  if (o.status === 'ACCEPTED_BY_BANK') {
    const currentDate = moment();
    const transactionUpdatedDate = moment(o.txnStatusLastUpdatedAt);
    const value = (currentDate.diff(transactionUpdatedDate, 'minute'));

    if (from === 'FINOPS') {
      return 'ALERT';
    } else if (process.env.REACT_APP_TRANSACTION_UPDATE_TIME && value >= parseInt(process.env.REACT_APP_TRANSACTION_UPDATE_TIME)) {
      return 'ALERT';
    }
    return null;
  }
  return null;
};

export const processManualReviewStatus = (status: string) => {
  if (status === 'APPROVED') {
    return 'Approved';
  } else if (status === 'REJECTED') {
    return 'Rejected';
  } else if (status === 'REVIEW_REQUIRED') {
    return 'Name check >';
  }
};

export const processFinOpsTransactionStatusData = (data: any) => {
  return _.compact(data.map((o: any) => {
    if (o.status === 'ACCEPTED_BY_BANK') {
      const transactionDate = moment(o.txnCreatedAt);
      const transactionUpdatedDate = moment(o.txnStatusLastUpdatedAt);

      const value = (transactionUpdatedDate.diff(transactionDate, 'minute'));
      if (process.env.REACT_APP_TRANSACTION_UPDATE_TIME && value >= parseInt(process.env.REACT_APP_TRANSACTION_UPDATE_TIME)) {
        return null;
      }
      return o;
    }
    return o;
  }));
};

export const processMarketStatus = (status: string) => {
  if (status === 'ACTIVE') {
    return 'Active';
  } else if (status === 'INACTIVE') {
    return 'Inactive';
  } else if (status === 'CLOSE_ONLY' || status === 'CLOSEONLY') {
    return 'Close only';
  } else if (status === 'HALTED') {
    return 'Halted';
  } else if (status === 'DISABLED') {
    return 'Disabled';
  } else if (status === 'LONGONLY') {
    return 'Long only';
  } else if (status === 'SHORTONLY') {
    return 'Short only';
  } else if (status === 'FULL') {
    return 'Full';
  }
};

export const processAccountKycStatus = (status: string) => {
  const statusArr = ['KYC_APPROVED|VERIFIED', 'KYC_NOT_READY|REVIEW', 'KYC_READY|REVIEW', 'KYC_PROCESSING|REVIEW', 'KYC_INFO_REQUIRED|REVIEW', 'KYC_DOC_REQUIRED|REVIEW', 'KYC_MANUAL_REVIEW|REVIEW', 'KYC_DENIED|ALERT'];

  return _.filter(statusArr, (s) => s.includes(status)).join('').split('|')[1];
};

export const processAccountStatus = (status: string) => {
  const statusArr = ['PENDING|REVIEW', 'OPEN|VERIFIED', 'OPEN_NO_NEW_TRADES|WARNING', 'CLOSED|ALERT'];

  return _.filter(statusArr, (s) => s.includes(`${status}|`)).join('').split('|')[1];
};

export const processAccountType = (status: string) => {
  if (status === 'CASH_EQUITY') {
    return 'EQUITY';
  }

  return status;
};

export const processDisplayZero = (value: number) => {
  if (!value || value === 0) {
    return 0;
  }

  return value;
};

export const processMarketConditions = (status: string) => {
  if (status === 'NORMAL') {
    return 'Normal';
  } else if (status === 'UNKNOWN') {
    return 'Unknown';
  } else if (status === 'TRADING_HALT') {
    return 'Trading halt';
  }
};

export const processPepRelevance = (pepType: string) => {
  if (pepType === 'PTC_DIRECTOR_OR_SHAREHOLDER') {
    return 'A director or 10% shareholder of a publicly traded corporation';
  } else if (pepType === 'BROKERAGE_FIRM_EMPLOYEE') {
    return 'Employed by a brokerage firm or securities exchange';
  } else if (pepType === 'HIGH_LEVEL_PUBLIC_OFFICIAL') {
    return 'A high public official';
  } else if (pepType === 'NONE') {
    return 'None of these apply to me';
  } else {
    return '-'
  }
};

export const setAccordionExpandByFilters = (filters: any) => {
  if (_.findIndex(filters, (f: any) => f && f.isChecked) !== -1) {
    return true;
  } 
  return false;
};

export const processReportStatus = (status: string) => {
  if (status === 'SENT_TO_BANK') {
    return 'Sent to bank';
  } else if (status === 'READY_TO_SEND') {
    return 'Ready to send';
  }
};

export const formatTime = (value: string, format: string) => {
  return moment(value, 'HH:mm:ss').format(format);
};

export const formatDate = (value: string, format?: string, isUTC?: boolean) => {
  if (isUTC) {
    return moment(value).utc().format('DD MMM YYYY h:mm a');  
  }
  return moment(value).format('DD MMM YYYY h:mm a');
};

export const processHolidayDate = (value: string) => {
  return moment(value).utc().format('dddd, DD.MM');
};

export const processPaymentProviderName = (value: string, data: any) => {
  if (data.type === 'DEPOSIT' && value === 'Leantech') {
    return 'Lean';
  } else if (value === 'Nuvei') {
    return value;
  } else if(value === 'Bank_wire'){
  return "Bank wire"
  }else {
    return '-';
  }
};

export const getReportBatchIdSelectionLimit = () => {
  return parseInt(process.env.REACT_APP_REPORT_BATCH_ID_SELECTION_LIMIT || '0');
};

export const processSettlementType = (value: string, data: any) => {
  if (data.type === 'DEPOSIT') {
    return 'DW deposit';
  } else if (data.type === 'WITHDRAW') {
    return 'DW withdrawal';
  } else {
    return '-';
  }
};

export const processAmount = (value: string | number, data: string, key: string) => {
  const keys = ["exinityAmount", "leanAmount", "exinityFredAmount", "niAmount"];
  if (keys.includes(key) && (value === 0 || value === null)) {
    return "$0";
  } else if (value) {
    return `$${value}`  
  } else {
    return '$0';
  }
};

export const processDollarAmount = (value: any, data: string, key: string) => {
  let val = value;
  if (val) {
    const v = parseInt(val);

    if (val.toString().endsWith('.')) {
      val = val.toString().slice(0, -1);
    }

    if (v > 0) {
      return `+ $${val}`;
    } else if (v < 0) {
      return `- $${val.toString().replace('-', '')}`;
    }
  } else {
    return '$0';
  }
}

export const processAmountWithDecimals = (value: string | number) => {
  return parseFloat(value.toString()).toFixed(2);
};

export const processReferralReason = (value: string) => {
  if (value === 'DEPOSIT_PROMOTIONAL') {
    return 'Deposit Promotional';
  } else if (value === 'REFERRAL') {
    return 'Referral';
  }

  return !value && '-';
};

export const processReferralApproval = (value: string | number, data: any) => {
  return `${value ? value : '0'}/${data.maxApproveGifts}`;
};

export const processGiftId = (value: string, data: any) => {
  return value ? value : data.txnNumber;
};

export const processTransactionType = (value: string) => {
  if (value === 'DEPOSIT') {
    return 'Deposit';
  } else if (value === 'WITHDRAW') {
    return 'Withdrawal'
  } else {
    return '-';
  }
};

export const processReportIdName = (value: string) => {
  if (value?.includes('withdrawals_')) {
    return value.replace('withdrawals_', 'w');
  } else if (value?.includes('deposits_')) {
    return value.replace('deposits_', 'd');
  } else {
    return value || '-';
  }
};

export const processTransactionCurrency = (currency: string, record: any) => {
  return (currency === 'USD') ? '-' : `${currency} ${record.amount}`;
};

export const processReconStatus = (status: string) => {
  if (status === 'NORMAL') {
    return 'Normal';
  } else if (status === 'DISCREPANCY') {
    return 'Discrepancy >';
  } else if (status === 'RESOLVED') {
    return 'Resolved >';
  }
};

export const getFirstLetters = (string: string) => {
  const firstLetters = string
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');

  return firstLetters;
};

export const  capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getExactRole = (value: string[]) => {
  return value.join('').replace('default-roles-fred', '').replace('uma_authorization', '');
};

export const isAdminRole = (value: string) => {
  return value === 'SUPER_ADMIN' ? true : false;
};

export const checkPermissions = (permissions: any, resourceName: string, ignoreView?: boolean) => {
  if (permissions.length && resourceName) {
    const o = _.find(permissions, (p) => p.resourceName === resourceName);

    if (o && o.isVisible) {
      if (ignoreView && o.permissions === 'READ') {
        return true;
      } else if (o.permissions === 'READ') {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
  return false;
};

export const  capitalizeFirstLetterOfEachWord = (value: string) => {
  const str = value.toLowerCase();
  return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

export const getQueryMap = (query: string): Record<string, string | string[]> => {
  const map: Record<string, string | string[]> = {};

  if (!query) {
    return map;
  }

  // split the query string into an array of key-value pairs
  const params = query.split('&');

  // loop through each key-value pair and add it to the map
  for (const param of params) {
    const [key, value] = param.split('=');

    if (key in map) {
      // the key already exists, so we need to check if it is an array, if not, make it an array and add the new value
      if (Array.isArray(map[key])) {
        // it's already an array - push the new value into the array
        (map[key] as string[]).push(value);
      } else {
        // it's not an array - make it an array and add both values
        map[key] = [map[key], value] as any;
      }
    } else {
      // put the value into the map
      map[key] = value;
    }
  }

  return map;
};

export const processCheckType = (value: string | number, data: any, key: string) => {
  if (data.poaVerification === 'MANUAL_REVIEW_REQUIRED') {
    return 'PoA check';
  } else if ((!data.poaVerification || data.poaVerification !== 'MANUAL_REVIEW_REQUIRED') && data.idVerification === 'MANUAL_REVIEW_REQUIRED') {
    return 'ID check';
  } else if (data.dwKycStatus === 'KYC_INFO_REQUIRED' || data.dwKycStatus === 'KYC_DOC_REQUIRED') {
    return 'DW check';
  } else {
    return '-';
  }
};

export const getTimeElapsed = (date: Date) => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;

  if (diff < minute) {
    return 'Just now';
  } else if (diff < hour) {
    const minutes = Math.floor(diff / minute);
    return `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
  } else if (diff < day) {
    const hours = Math.floor(diff / hour);
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  } else {
    return `${moment(date).format('DD MMM YY hh:mm a')}`;
  }
};

export const formatMessagePayloadStatus = (status: string) => {
  if (status === 'QUEUED') {
    return 'Queued';
  } else if (status === 'DW_CLEARED') {
    return 'DW cleared';
  } else if (status === 'APPROVED') {
    return 'Approved';
  } else if (status === 'PENDING') {
    return 'Pending';
  } else if (status === 'CREDIT_ERROR') {
    return 'Credit error';
  } else if (status === 'VERY_HIGH') {
    return 'Very high risk';
  } else {
    return status;
  }
};

export interface FieldType {
  [x: string]: string | boolean | number | any[]
};

export const processClientInfoData = (info: any, details: any) => {
  const profile = details.map((item: any, i: number) => {
    if (item.name && info && info[item.name] && !item.pickValuesFrom) {
      item.value = info[item.name];
      if (item.verified) {
        item.verified.value = _.get(info, item.verified.verifiedFrom);
      }
      if (typeof info[item.name] === 'boolean') {
        item.value = info[item.name] === true ? 'Yes' : 'No';
      }
      if (item.isDate) {
        item.value = moment(info[item.name]).format('MM.DD.YYYY')
      }
    } else if ((item.section && info[item.section]) || (item.renderBy && info[item.renderBy])) {
      if (item.rootField) {
        let rootFieldValue = info[item.rootField.name];

        if (typeof info[item.rootField.name] === 'boolean' && !item.rootField.isBoolean) {
          rootFieldValue = info[item.rootField.name] === true ? 'Yes' : 'No';
        }
        item.rootField.value = rootFieldValue;
      }
      if (item.fields && !item.isFieldsEditable) {
        item.fields = _.flatten(info[item.section].map((f: { [x: string]: any; }, i: number) => {
          const fieldsArr: FieldType[] = [];
          Object.keys(f).map((key, index) => {
            if (key !== 'id') {
              fieldsArr.push({
                name: key,
                label: key,
                value: f[key],
                isEdit: false,
                isFieldHover: false,
                fieldType: key === 'countryShortName' ? 'SELECT' : 'TEXT',
                ...(key === 'countryShortName' && {dropdownList: [], isCountry: true}),
                ...(f['id'] && {id: f['id']}),
                fieldIndex: i
              });
            }
          });
          return fieldsArr.reverse();
        }));
      } else if (item.fields && item.isFieldsEditable) {
        item.fields = _.flatten(info[item.section].map((f: { [x: string]: any; }, i: number) => {
          const fieldsArr: FieldType[] = [];
          Object.keys(f).map((key, index) => {
            if (key !== 'id' && !fieldsArr.some((field) => field.name === f.id)) {
              fieldsArr.push({
                name: f.id,
                label: f.question,
                value: f.answer
              });
            }
          });
          return fieldsArr;
        }));
      }
      if (item.renderBy) {
        item.renderByField = info[item.renderBy];
      }
    } else if (item.pickValuesFrom) {
      if (item.pickValuesFrom.length > 1) {
        let value = item.pickValuesFrom.map((v: string) => {
          item[`${v}Value`] = _.get(info, v);
          return _.get(info, v);
        }).join(item.separator);

        if (item.name === 'phone') {
          value = value.trim() !== '' ? '+' + value : '';
        } else if (item.name === 'annualIncome' || item.name === 'netWorth') {
          if (value.split('-')[1] === '' || value.split('-')[1] === ' ')  {
            value = value.replace('-', '') + '+';
          }
        }
        
        item.value = value;
      } else {
        if (typeof info[item.pickValuesFrom[0]] === 'boolean') {
          item.value = _.get(info, item.pickValuesFrom[0]) === true ? 'Yes' : 'No';
        } else {
          item.value = _.get(info, item.pickValuesFrom[0]);
        }

        if (['industry', 'employer', 'position'].includes(item.name)) {
          if (['RETIRED', 'NOT_EMPLOYED', 'STUDENT'].includes(_.get(info, 'employment.status'))) {
            item.isDisabled = true;
          }
        }
      }
    }
    return item;
  });

  return profile;
};

export const formatFirstLetter = (value: string) => {
  if (!value) return '';

  const words = value.toLowerCase().split('_');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

export const marketFilterTitles: any = {
  'instrumentType': 'Instrument type',
  'instrumentSubType': '2nd instrument type',
  'tradingVenueStatus': 'Market status',
  'internalStatus': 'Internal status'
};

const processArrayFilters = (data: any) => {
  return data.map((d: any) => {
    return {
      key: d,
      label: formatFirstLetter(d),
      isChecked: false
    }
  });
};

const processObjectFilters = (data: any, filterBy?: string) => {
  if (filterBy) {
    return Object.keys(data).flatMap((k: string) => {
      return data[filterBy].map((item: any) => {
        return {
          key: item,
          label: formatFirstLetter(item),
          isChecked: false,
          instrumentType: filterBy
        }
      });
    });
  } else {
    return Object.keys(data).flatMap((k: string) => {
      return data[k].map((item: any) => {
        return {
          key: item,
          label: formatFirstLetter(item),
          isChecked: false,
          instrumentType: k
        }
      });
    });
  }
};

export const processMarketsFilterData = (data: Record<string, any[]>) => {
  const result = Object.keys(data).map((key: string) => {
    return {
      parentKey: key,
      title: marketFilterTitles[key],
      filters: _.isArray(data[key]) ? processArrayFilters(data[key]) : (_.isObject(data[key]) ? processObjectFilters(data[key]) : [])
    }
  });

  return result;
};

export const processMarketsSelectedFilter = (data: Record<string, any[]>, propertyName: string, instrumentTypeFilters: any, prevData: any) => {
  const o = _.filter(instrumentTypeFilters, (d) => d.isChecked);

  const result = Object.keys(data).map((key: string) => {
    if (propertyName === key) {
      return {
        parentKey: key,
        title: marketFilterTitles[key],
        filters: instrumentTypeFilters
      }
    } else {
      if (o.length === 1 && ['EQUITY', 'CFD'].includes(o[0].key)) {
        return {
          parentKey: key,
          title: marketFilterTitles[key],
          filters: _.isArray(data[key]) ? _.uniq(processArrayFilters(data[key])) : (_.isObject(data[key]) ? _.uniqBy(processObjectFilters(data[key], o[0].key), 'key') : [])
        }
      } else {
        return {
          parentKey: key,
          title: marketFilterTitles[key],
          filters: _.isArray(data[key]) ? processArrayFilters(data[key]) : (_.isObject(data[key]) ? processObjectFilters(data[key]) : [])
        }
      }
    }
  });

  const mergedData = result.map((item: any) => {
    const prevItem = prevData.find((prev: any) => prev.parentKey === item.parentKey);
    if (prevItem) {
      return {
        ...item,
        filters: item.filters.map((filter: any) => {
          const prevFilter = prevItem.filters.find((prevFilter: any) => prevFilter.key === filter.key);
          if (prevFilter) {
            return {
              ...filter,
              isChecked: prevFilter.isChecked
            };
          }
          return filter;
        })
      };
    }
    return item;
  });

  return mergedData;
};

export const processCashBalanceTitle = (type: string) => {
  if (type === 'wallet') {
    return 'Wallet';
  } else if (type === 'equity') {
    return 'Cash equities';
  } else if (type === 'crypto') {
    return 'Crypto';
  } else if (type === 'cfd') {
    return 'CFD';
  }
};

export const processSignValue = (value: number) => {
  return Math.sign(value) === -1 ? 'negative' : (Math.sign(value) === 1 ? 'positive' : ''); 
};

export const processClientAccountDetailsData = (data: any, details: any) => {
  const processData = details.map((item: any, i: number) => {
    if (item.name && data && data[item.name] && !item.pickValuesFrom) {
      item.value = data[item.name];
      if (item.verified) {
        item.verified.value = _.get(data, item.verified.verifiedFrom);
      }
      if (typeof data[item.name] === 'boolean') {
        item.value = data[item.name] === true ? 'Yes' : 'No';
      }
      if (item.isDate) {
        item.value = moment(data[item.name]).format('MM.DD.YYYY, h:mm a')
      }
    } else if ((item.section && data[item.section]) || (item.renderBy && data[item.renderBy])) {
      if (item.fields) {
        item.fields = _.flatten(data[item.section].map((f: { [x: string]: any; }, i: number) => {
          const fieldsArr: FieldType[] = [];
          Object.keys(f).map((key, index) => {
            fieldsArr.push({
              name: key,
              label: key,
              value: f[key]
            });
          });
          return fieldsArr.reverse();
        }));
      }
      if (item.renderBy) {
        item.renderByField = data[item.renderBy];
      }
    } else if (item.pickValuesFrom) {
      if (item.pickValuesFrom.length > 1) {
        let value = item.pickValuesFrom.map((v: string) => {
          item[`${v}Value`] = _.get(data, v);
          return _.get(data, v);
        }).join(item.separator);

        item.value = value;
      } else {
        if (typeof data[item.pickValuesFrom[0]] === 'boolean') {
          item.value = _.get(data, item.pickValuesFrom[0]) === true ? 'Yes' : 'No';
        } if (item.isDate && _.get(data, item.pickValuesFrom[0])) {
          item.value = moment(_.get(data, item.pickValuesFrom[0])).format('MM.DD.YYYY, h:mm a');
        } else {
          if (_.get(data, item.pickValuesFrom[0])) {
            item.value = item.currency ? (processSignValue(_.get(data, item.pickValuesFrom[0])) === 'negative' ? `-${item.currency}${(_.get(data, item.pickValuesFrom[0])).toString().replace('-', '')}` : `${item.currency}${_.get(data, item.pickValuesFrom[0])}`) : _.get(data, item.pickValuesFrom[0]);
          } else {
            item.value = '-';
          }
        }
      }
    }
    return item;
  });

  return processData;
};

export const convertMsecToHours = (msec: number) => {
  const date = new Date(msec);
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;

  return formattedTime;
};

export const processCountryRestrictionStatus = (value: string) => {
  if (value === "SUPPORTED") {
    return "Supported";
  } else if (value === "IOS_ONLY_RESTRICTED") {
    return "iOS-only restricted";
  } else if (value === "RESTRICTED") {
    return "Restricted";
  }
};