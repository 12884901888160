/* eslint-disable react-hooks/exhaustive-deps */
import {
  useContext,
  useState,
  useEffect,
  MouseEvent,
  ChangeEvent,
} from "react";
import { CSVLink } from "react-csv";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { useSnackbar } from "notistack";
import {
  IconButton,
  Box,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import moment from "moment";
import { GlobalContext } from "context";
import CssContainer from 'components/CssContainer';
import CssTitle from 'components/CssTitle';
import Download from "components/icons/Download";
import CssFlexBox from "components/CssFlexBox";
import CssTable from "components/CssTable";
import { CountriesHeaders } from "constants/countries-table-head-columns";
import ScreenDialog from "components/ScreenDialog";
import CssAccordion from "components/CssAccordion";
import CssButton from "components/CssButton";
import CssCheckbox from "components/CssCheckbox";
import { Order, checkPermissions, getQueryMap, setAccordionExpandByFilters } from "utils";
import useRequests from "hooks/request-hook";
import useApi from "hooks/api-hook";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: 32,
  marginTop: 60,
  marginBottom: 0,
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  paddingBottom: 14,
}));

const StyledIconButton = styled(IconButton)({
  top: "-10px",
  "&:hover": {
    backgroundColor: "transparent",
  },
});

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 5,
}));

const Countries = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    userPermissions,
    searchQuery,
    isSearchClosed,
    isFilterCleared,
    setIsFilterCleared,
    isUpdatePage,
    setIsUpdatePage,
    countriesFilterQuery,
    setCountriesFilterQuery,
    isFilterApplied,
    setIsFilterApplied,
    countriesStatusFilters,
    setCountriesStatusFilters,
    setIsFilterIconDisplay,
  } = useContext(GlobalContext);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState([]);
  const [downloadParams, setDownloadParams] = useState<any>(null);
  const {
    getCountriesRestrictionApi,
    getCountriesDownloadApi,
    postActivityLogApi,
    updateFeatureByCountryApi,
  } = useRequests();
  const getCountriesRestriction = useApi(getCountriesRestrictionApi);
  const getCountriesDownload = useApi(getCountriesDownloadApi);
  const updateFeatureByCountry = useApi(updateFeatureByCountryApi);
  const postActivityLog = useApi(postActivityLogApi);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState("countryName");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState<any>([]);
  const [isFiltersCleared, setIsFiltersCleared] = useState<boolean>(false);
  const [openFiltersScreen, setOpenFiltersScreen] = useState<boolean>(false);
  const [enableButtons, setEnableButtons] = useState<boolean>(true);

  const downloadFileHeaders = [
    { label: "Country Restriction Id", key: "countryRestrictionId" },
    { label: "Country Name", key: "countryName" },
    { label: "Residency", key: "residency" },
    { label: "Nationality", key: "nationality" },
    { label: "CFD Trading", key: "cfdTrading" },
    { label: "Crypto CFD Trading", key: "cryptoCFDTrading" },
    { label: "Leveraged Crypto CFD Trading", key: "leveragedCryptoCFDTrading" },
    { label: "Tax", key: "tax" },
    { label: "Withdrawal", key: "withdrawal" },
  ];
  const downloadFilename = `clients-${moment().format('MM-DD-yyyy')}`;

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const checkCountriesPermissions = () => {
    return checkPermissions(userPermissions, "COUNTRIES");
  };

  const onCheckboxClick = (event: ChangeEvent<HTMLInputElement>, key: string, index: number) => {
    const selectedFilters = _.find(countriesStatusFilters, (o) => o.parentKey === key).filters;
    const newFilters = selectedFilters.map((item: any) => {
      if (item.key === event.target.value) {
        item.isChecked = event.target.checked;
      }

      return item;
    });

    setCountriesStatusFilters((csf: any) => _.map(csf, (c) => {
      if (c.parentKey === key) {
        c.filters = newFilters;
      }
      return c;
    }));
  };

  const onClearFilters = () => {
    setIsFiltersCleared(true);
    setCountriesFilterQuery('');
    setIsFilterApplied(false);
    setIsFilterIconDisplay(false);
    enqueueSnackbar('All filters have been cleared successfully.', {
      variant: 'defaultAlert'
    });
    setCountriesStatusFilters((csf: any) =>
      _.map(csf, (c) => {
        c.filters = _.map(c.filters, (f: any) => {
          if (f.isChecked) {
            f.isChecked = false;
          }
          return f;
        });
        return c;
      })
    );
    setIsFilterCleared(true);
    setPage(0);
  };

  type Params = {
    page: number;
    limit: number;
    q?: string;
    sort?: number;
    sortBy?: string;
    countriesFilterQuery?: string;
  };

  const getCountriesCall = (params: Params) => {
    const {
      page,
      limit,
      q,
      sort,
      sortBy,
      countriesFilterQuery,
    } = params;
    getCountriesRestriction
      .request(
        page + 1,
        limit,
        q,
        sort,
        sortBy,
        countriesFilterQuery
      )
      .then((res) => {
        if (res.status === 200) {
          const { totalcount } = res.headers;
          setTotalCount(parseInt(totalcount));
          setData(res.data);

          if (isFilterApplied) {
            enqueueSnackbar("Filters have been applied successfully.", {
              variant: "successWithUndo",
              onUndo: onClearFilters,
            });
            setIsFilterApplied(false);
          }
        }
        if (isUpdatePage) setIsUpdatePage(false);
        if (isFilterCleared) setIsFilterCleared(false);
        if (isFiltersCleared) setIsFiltersCleared(false);
      });
  };

  type DownloadParams = {
    q?: string;
    countriesFilterQuery?: string;
  };

  const getCountriesDownloadCall = (params: DownloadParams) => {
    const { q, countriesFilterQuery } = params;

    if (!checkCountriesPermissions()) return;

    const data = params;
    delete data.countriesFilterQuery;
    if (countriesFilterQuery) {
      const filters = _.omitBy(getQueryMap(countriesFilterQuery), _.isNil);
      setDownloadParams({...data, ...filters});
    } else {
      setDownloadParams(data);
    }
    
    getCountriesDownload
      .request(q, countriesFilterQuery)
      .then((res) => {
        if (res.status === 200) {
          setDownloadData(res.data);
          setIsDownloadEnabled(true);
        }
      });
  };

  const triggerActivityLog = () => {
    let payload = {
      page: 'COUNTRIES',
      action: 'DOWNLOAD'
    };
    if (downloadParams) {
      payload = {...payload, ...downloadParams};
    }
    postActivityLog.request(_.omitBy(payload, _.isEmpty));
  };

  const handleFilters = () => {
    setOpenFiltersScreen(true);
  };

  const onFiltersScreenClose = (event: {},
  reason: 'backdropClick') => {
    setOpenFiltersScreen(false);
  };

  const onApply = () => {
    let query = '';
    setOpenFiltersScreen(false);
    setEnableButtons(true);
    setIsFilterApplied(true);
    setIsFilterIconDisplay(true);
    setIsDownloadEnabled(false);
    _.map(countriesStatusFilters, (o: any) => {
      _.map(o.filters, (f: any) => {
        if (f.isChecked) {
          query += `&${o.parentKey}=${f.key}`;
        }
      });
    });

    if(query) {
      setCountriesFilterQuery(query);
    }
    setIsUpdatePage(true);
  };

  const onCancel = () => {
    setOpenFiltersScreen(false);
    setEnableButtons(true);
  };

  const onFieldChange = (event: any, key: string, r?: any) => {
    updateFeatureByCountry
      .request(r.countryRestrictionId, { [key]: event.target.value })
      .then((res) => {
        setData((cd: any) =>
          _.map(cd, (d: any) => {
            if (r.countryRestrictionId === d?.countryRestrictionId) {
              d[key] = event.target.value;
            }
            return d;
          })
        );
        enqueueSnackbar(res.data.message, {
          variant: "successAlert",
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error.message, {
          variant: "errorAlert",
        });
      });;
  };

  useEffect(() => {
    const newFilters = _.map(countriesStatusFilters, (o) => {
      if (_.findIndex(o.filters, (f: any) => f.isChecked) !== -1) {
        return true;
      }
      return false;
    });
    const isEnabled = _.compact(newFilters)[0];

    if (isFiltersCleared && !isEnabled) {
      setEnableButtons(false);
    } else if (isEnabled === true) {
      setEnableButtons(false);
    } else {
      setEnableButtons(true);
    }
  }, [
    countriesStatusFilters,
    isFiltersCleared,
  ]);

  useEffect(() => {
    if (!searchQuery) {
      if (countriesFilterQuery) {
        setPage(isUpdatePage ? 0 : page);
      }
      getCountriesCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, countriesFilterQuery: countriesFilterQuery });
      getCountriesDownloadCall({
        countriesFilterQuery: countriesFilterQuery,
      });
    } else if (searchQuery !== '' && !isSearchClosed) {
      setPage(isUpdatePage ? 0 : page);
      getCountriesCall({ page, limit: rowsPerPage, sort: order === 'asc' ? 1 : -1, sortBy: orderBy, q: searchQuery, countriesFilterQuery: countriesFilterQuery });
      getCountriesDownloadCall({
        q: searchQuery,
        countriesFilterQuery: countriesFilterQuery,
      });
    }
  }, [page, rowsPerPage, order, orderBy, isFilterCleared, countriesFilterQuery, searchQuery, isSearchClosed, isFiltersCleared, isUpdatePage]);

  return (
    <CssContainer>
      <CssFlexBox sx={{ justifyContent: "space-between" }}>
        <CssTitle variant="h4" underline>
          Countries
        </CssTitle>
        {isDownloadEnabled && checkCountriesPermissions() ? (
          <CSVLink
            data={downloadData}
            headers={downloadFileHeaders}
            filename={downloadFilename}
            style={{
              position: "relative",
              top: 36,
              width: 30,
              height: 30,
              border: "1px solid #000",
              borderRadius: 30,
              marginLeft: 22,
            }}
            onClick={triggerActivityLog}
          >
            <Download className={"download"} />
          </CSVLink>
        ) : (
          <StyledIconButton
            disabled
            style={{ position: "relative", top: 40, width: 30, height: 30 }}
          >
            <Download fill={"#E5E5E5"} />
          </StyledIconButton>
        )}
      </CssFlexBox>

      <CssTable
        headers={CountriesHeaders}
        totalCount={totalCount}
        data={data}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        highlightText={searchQuery}
        onRequestSort={handleRequestSort}
        onTablePageChange={handleChangePage}
        onTableRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: { md: 3, xs: 0 } }}
        onFilters={handleFilters}
        isDesktopFilters={true}
        rowActions={{
          onChange: onFieldChange,
        }}
        permissionCheck={checkCountriesPermissions()}
      />

      <ScreenDialog
        title={"Filters"}
        hideCloseBtn={true}
        disableEscapeKeyDown={true}
        open={openFiltersScreen}
        onScreenClose={onFiltersScreenClose}
      >
        <StyledBox>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            Filters
          </Typography>
          <Typography
            variant="body2"
            sx={{ cursor: "pointer" }}
            onClick={onClearFilters}
          >
            &mdash; Clear filters
          </Typography>
        </StyledBox>
        <Box sx={{ m: 4, mt: 0 }}>
          {countriesStatusFilters &&
            countriesStatusFilters.map((item: any, index: number) => (
              <CssAccordion
                title={item.title}
                key={index}
                isExpand={setAccordionExpandByFilters(item.filters)}
              >
                <FormGroup>
                  {item.filters.map((filter: any, i: number) => (
                    <StyledFormControlLabel
                      control={
                        <CssCheckbox
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onCheckboxClick(e, item.parentKey, i)
                          }
                        />
                      }
                      label={filter.label}
                      checked={filter.isChecked}
                      key={i}
                      value={filter.key}
                    />
                  ))}
                </FormGroup>
              </CssAccordion>
            ))}

          <Grid container>
            <Grid item xs={12} sx={{ textAlign: "center", mt: 6.5 }}>
              <CssButton
                variant="outlined"
                sx={{ width: "46.5%", mr: 1, ml: 0 }}
                onClick={onCancel}
                disabled={enableButtons}
              >
                Cancel
              </CssButton>
              <CssButton
                variant="contained"
                sx={{ width: "46.5%", mr: 0, ml: 1 }}
                onClick={onApply}
                disabled={enableButtons}
              >
                Apply
              </CssButton>
            </Grid>
          </Grid>
        </Box>
      </ScreenDialog>
    </CssContainer>
  );
};

export default Countries;