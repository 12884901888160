import React, { Suspense } from "react";
import * as FlagIcons from "country-flag-icons/react/3x2";

// Function to dynamically import the flag component
const loadFlagComponent = (countryCode: string) => {
  const Component = FlagIcons[countryCode as keyof typeof FlagIcons];
  if (!Component) {
    // throw new Error(`Flag for country code "${countryCode}" not found.`);
    return null;
  }
  return React.lazy(() => Promise.resolve({ default: Component }));
};

interface CountryFlagProps {
  countryCode: string,
  title: string
}

const CountryFlag = ({ countryCode, title }: CountryFlagProps) => {
  const FlagComponent = loadFlagComponent(countryCode);

  return (
    <Suspense fallback={<div>...</div>}>
      {FlagComponent ? <FlagComponent title={title} style={{ width: 38, height: 25 }} /> : ""}
    </Suspense>
  );
};

export default CountryFlag;
