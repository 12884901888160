/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, IconButton, Select, MenuItem, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Eye from 'components/icons/Eye';
import EyeClosed from 'components/icons/EyeClosed';
import CaretBack from 'components/icons/CaretBack';
import CaretBackActive from 'components/icons/CaretBackActive';
import Caret from 'components/icons/Caret';
import CssTitle from 'components/CssTitle';
import CssButton from 'components/CssButton';
import useRequests from 'hooks/request-hook';
import useApi from 'hooks/api-hook';
import { GlobalContext } from 'context';
import { isAdminRole, checkPermissions, formatDate } from 'utils';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: '22px 32px',
  borderRadius: 10,
  marginBottom: 15
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: '#5B5959',
  fontSize: '0.875rem',
  borderWidth: 0,
  width: 82,
  marginTop: 2,
  marginRight: 4,
  '& .MuiSelect-select': {
    padding: '12px 16px'
  },
  '& fieldset': {
    borderWidth: 0,
  },
  '& .MuiSelect-icon': {
    top: 11,
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline ': {
      borderColor: `${theme.palette.primary.light}`,
    },
    '& .MuiSelect-icon.MuiSelect-iconOpen': {
      top: 5,
    }
  }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: '-60px',
  top: '-6px',
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

const Permissions = (props: any) => {
  const { data, onBack } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { permissions, setPermissions, userRole, userPermissions } = useContext(GlobalContext);
  const { getPermissionsByRoleIdApi, updatePermissionsByRoleIdApi } = useRequests();
  const getPermissionsByRoleId = useApi(getPermissionsByRoleIdApi);
  const updatePermissionsByRoleId = useApi(updatePermissionsByRoleIdApi);
  const [permissionsData, setPermissionsData] = useState([]);
  const [isBackButtonActive, setIsBackButtonActive] = useState<boolean>(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState<string>('');

  const onToggleVisible = (module: string, submodule?: string) => {
    setPermissions((pms: any) => pms.map((item: any) => {
      if (item.module === module && item.submodules) {
        item.submodules.map((s: any, i: number) => {
          if (s.resourceName === submodule) {
            s.isVisible = !s.isVisible;
          }
          return s;
        });
      } else if (item.module === module) {
        item.isVisible = !item.isVisible;
      }
      
      return item;
    }));
  };

  const onToggleBackButton = () => {
    setIsBackButtonActive(!isBackButtonActive);
  };

  const onSubModuleChange = (event: ChangeEvent<HTMLInputElement>, submodule: string, module: string) => {
    setPermissions((pms: any) => pms.map((item: any) => {
      if (item.module === module && item.submodules) {
        item.submodules.map((s: any, i: number) => {
          if (s.resourceName === submodule) {
            s.permissions = event.target.value;
          }
          return s;
        });
      }
      return item;
    }));
  };

  const onModuleChange = (event: ChangeEvent<HTMLInputElement>, module: string) => {
    setPermissions((pms: any) => pms.map((item: any) => {
      if (item.module === module) {
        item.permissions = event.target.value;
      }
      return item;
    }));
  };

  type PermissionsParams = {
    resourceName: string,
    permissions: string,
    isVisible: boolean
  }

  const onSavePermissions = () => {
    let payload: PermissionsParams[] = [];
    permissions.map((p: any) => {
      if (p.submodules) {
        p.submodules.map((ps: any) => {
          payload.push({
            resourceName: ps.resourceName,
            permissions: ps.permissions,
            isVisible: ps.isVisible
          });
        });
      } else {
        payload.push({
          resourceName: p.module,
          permissions: p.permissions,
          isVisible: p.isVisible
        });
      }
    });

    updatePermissionsByRoleId.request(data.id || data.roleId, payload).then((res) => {
      if (res.status === 200) {
        enqueueSnackbar('Role permissions has been updated successfully.', {
          variant: 'successAlert'
        });
        handleGoBack();
      }
    });
  };

  const checkRolesPermissions = () => {
    if (!isAdminRole(userRole)) {
      return checkPermissions(userPermissions, 'ROLES_PERMISSION');
    } else {
      return true;
    }
  };

  const handleGoBack = () => {
    setPermissions((pms: any) => permissions);
    onBack();
  };

  useEffect(() => {
    getPermissionsByRoleId.request(data.id || data.roleId).then((res) => {
      if (res.status === 200) {
        setLastUpdatedDate(res.data[0].lastUpdatedAt)
        setPermissionsData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    let data = permissions;
    if (permissionsData.length) {
      permissionsData.map((pd: any) => {
        data = data.map((p: any) => {
          if (p.submodules) {
            p.submodules = p.submodules.map((ps: any) => {
              if (ps.resourceName === pd.resourceName) {
                ps.permissions = pd.permissions;
                ps.isVisible = pd.isVisible;
              }
              return ps;
            });
          } else {
            if (p.module === pd.resourceName) {
              p.isVisible = pd.isVisible;
              p.permissions = pd.permissions;
            }
          }
          return p;
        });
      });

      setPermissions(data);
    }
  }, [permissionsData]);

  return (
    <>
      <Grid container sx={{pb: 4}}>
        <Grid item xs={12} justifyContent={'space-between'} sx={{ display: 'flex', mt: 10 }}>
          <CssTitle variant='h6' sx={{fontSize: '1.25rem'}}>
            Role permissions 
          </CssTitle>
        </Grid>
        <Grid item xs={12} justifyContent={'space-between'} sx={{ display: 'flex', mt: 3.75, position: 'relative' }}>
          <Box>
            <StyledIconButton onMouseEnter={onToggleBackButton} onMouseLeave={onToggleBackButton} onClick={handleGoBack}>
              {isBackButtonActive ? <CaretBackActive /> : <CaretBack />}
            </StyledIconButton>
            <CssTitle variant='h6' sx={{fontSize: '1.25rem'}}>
              {data.roleName}
              {data.members && <Typography variant='subtitle2' sx={{display: 'inline-block', ml: 2}}>{data.members} {data.members === '1' || data.members === '0' ? 'member': 'members'}</Typography>}
            </CssTitle>
          </Box>
          <CssTitle variant='subtitle2' sx={{display: 'inline-block', mt: 1}}>
            Edited on {formatDate(lastUpdatedDate)}
          </CssTitle>
        </Grid>
        <Grid item xs={12} sx={{mt: 4.25}}>
          {permissions && permissions.map((p: any, index: number) => (
            <StyledBox key={index}>
              <Grid container>
                <Grid item xs={12} justifyContent={'space-between'} sx={{ display: 'flex' }}>
                  <CssTitle variant='h6' sx={{fontSize: '1.25rem', mt: 1, ...(!p.isVisible && {color: '#C4C4C4'})}}>
                    {p.displayName}
                  </CssTitle>
                  <Box>
                    {!p.submodules && <>
                      <StyledSelect
                        onChange={(event: any) => {onModuleChange(event, p.module)}}
                        value={p.permissions}
                        IconComponent={Caret}
                        {...(!p.isVisible && {disabled: true})}
                      >
                        <MenuItem value={'READ'}>View</MenuItem>
                        <MenuItem value={'WRITE'}>Edit</MenuItem>
                      </StyledSelect>
                      <IconButton sx={{ width: 23, height: 23, mt: 1 }} onClick={() => onToggleVisible(p.module)}>
                        {p.isVisible ? <Eye /> : <EyeClosed />}
                      </IconButton>
                    </>}
                  </Box>
                </Grid>
                {p.submodules && p.submodules.map((s: any, i: number) => (
                  <Grid item xs={12} justifyContent={'space-between'} sx={{ display: 'flex' }} key={i}>
                    <CssTitle variant='body1' sx={{mt: 1.25}}>
                      {s.displayName}
                    </CssTitle>
                    <Box>
                      <StyledSelect
                        onChange={(event: any) => {onSubModuleChange(event, s.resourceName, p.module)}}
                        value={s.permissions}
                        IconComponent={Caret}
                        {...(!s.isVisible && {disabled: true})}
                      >
                        <MenuItem value={'READ'}>View</MenuItem>
                        <MenuItem value={'WRITE'}>Edit</MenuItem>
                      </StyledSelect>
                      <IconButton sx={{ width: 23, height: 23, mt: 1 }} onClick={() => onToggleVisible(p.module, s.resourceName)}>
                        {s.isVisible ? <Eye /> : <EyeClosed />}
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </StyledBox>
          ))}
        </Grid>
        {checkRolesPermissions() && <Grid item xs={12} sx={{ mt: 4, justifyContent: 'end', display: 'flex' }}>
          <CssButton variant='contained' sx={{width: '30%', mr: 0, ml: 1}} className='active' onClick={onSavePermissions}>Apply changes</CssButton>
        </Grid>}
      </Grid>
    </>
  );
};

export default Permissions;