/* eslint-disable react-hooks/exhaustive-deps */
import { MouseEvent, ChangeEvent, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Typography,
  TableContainer,
  Box,
  IconButton,
  TextField,
  useMediaQuery,
  Link,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { GlobalContext } from 'context';
import { Order, stableSort, getComparator } from 'utils';
import Caret from 'components/icons/Caret';
import Filters from 'components/icons/Filters';
import Eye from 'components/icons/Eye';
import EyeClosed from 'components/icons/EyeClosed';
import Download from 'components/icons/Download';
import EditPencil from 'components/icons/EditPencil';
import Plus from 'components/icons/Plus';
import Comments from 'components/icons/Comments';
import ActiveComments from 'components/icons/ActiveComments';
import CheckCaret from "components/icons/CheckCaret";
import CssChip from './CssChip';
import CssEllipse from './CssEllipse';
import CssCheckbox from './CssCheckbox';
import Pagination from "./Pagination";
import CountryFlag from './CountryFlag';

interface HeadCell {
  key: string
  label: string
  info? : string
};

interface StatusData {
  key: string
  type: string
  valid: string[]
  ignoreStatus?: string[]
};

interface TableProps {
  headers: any
  totalCount: number
  data: any
  orderBy?: number | string
  order?: Order
  onRequestSort?: Function
  page: number
  rowsPerPage: number
  sx?: any
  highlightText?: string
  isDesktopFilters?: boolean | false
  from?: string
  hasHeaderCheckbox?: boolean | false
  hasCheckbox?: boolean | false
  checkBoxStatus?: any
  rowClassName?: string
  isSelectAll?: boolean | false
  rowActions?: any
  permissionCheck?: boolean
  onStatusAction?: (o: any) => void
  onReviewStatusAction?: (o: any) => void
  onRowCheckbox?: (event: ChangeEvent<HTMLInputElement>) => void
  onSelectAll?: (event: ChangeEvent<HTMLInputElement>) => void
  onFilters?: (event: MouseEvent<unknown>) => void
  onTablePageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onTableRowsPerPageChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
  onRowClick?: (event: MouseEvent<unknown>, o: any) => void
  onToggleRowEditHover?: (rowData: any, key: string, isToggle: boolean) => void
  onToggleCommentsHover?: (rowData: any, isToggle: boolean) => void
  onEditButtonClicked?: (rowData: any, key: string) => void
  onEditKeyEnter?: (event: any, rowData: any, key: string) => void
  onEditChangeField?: (event: any, rowData: any, key: string) => void
  onLinkClick?: (rowData: any) => void
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '&.MuiTableContainer-root': {
    marginTop: 35,
    paddingBottom: 54,
    '&.set-scroll': {
      paddingBottom: 14,
      maxHeight: 580
    }
  }
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 500,
  borderBottomColor: theme.palette.text.secondary,
  lineHeight: 0,
  paddingRight: 0,
  padding: '8px 0 8px 16px',
  [theme.breakpoints.between('xs', 'sm')]: {
    padding: '14px 0 16px 16px',
    '& .MuiTableSortLabel-root': {
      display: 'block',
      position: 'relative',
      top: 12
    },
    '& .MuiTableSortLabel-icon': {
      display: 'block',
      textAlign: 'center'
    }
  }
}));

const StyledLabelTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontFamily: 'Roboto',
  color: theme.palette.text.secondary,
  '& .icon svg': {
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    display: 'block',
    width: 68,
    height: 20,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const StyledRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#C4C4C426',
    '&.no-row-click': {
      backgroundColor: 'transparent'
    }
  },
  '&.no-row-click': {
    cursor: 'default'
  },
  '& .MuiTableCell-root': {
    padding: '14px 0 14px 16px',
    borderBottomColor: theme.palette.divider
  },
  '&.active-notification-records': {
    backgroundColor: '#04F8DA0D'
  }
}));

const StyledTypography = styled(Typography)({
  '&.ALERT': {
    color: '#FF4534'
  },
  '&.WARNING': {
    color: '#FF802C'
  },
  '&.REVIEW': {
    color: '#FFC32A'
  },
  '&.VERIFIED': {
    color: '#02B9A2'
  }
});

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  borderBottom: 0,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#FFF',
  '&:last-child': {
    paddingRight: '7.3%',
    paddingLeft: '7.3%'
  },
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.25)',
  '& .rows-per-page': {
    border: '1px solid #C4C4C4',
    borderRadius: 30,
    marginRight: 66,
    width: 80,
    marginLeft: 15,
    '& .MuiTablePagination-select': {
      textAlign: 'left !important',
      textAlignLast: 'left !important',
      padding: '7.5px 20px',
      gap: 10,
      fontSize: '0.875rem'
    },
    '& .MuiTablePagination-selectIcon': {
      right: 10
    },
    '& .icon': {
      top: 3,
      right: 6
    }
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    paddingTop: '10px !important',
    '& .MuiTablePagination-toolbar': {
      padding: 0,
      display: 'inline-flex',
      '& .MuiTablePagination-selectLabel': {
        display: 'none'
      },
      '& .rows-per-page': {
        marginLeft: 0,
        marginRight: 18
      },
      '& .pagination-actions': {
        marginLeft: 10
      }
    }
  }
}));

const StyledEllipse = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  [theme.breakpoints.between('xs', 'sm')]: {
    textAlign: 'center',
    '& .MuiTypography-root': {
      display: 'none'
    }
  }
}));

const StyledEllipseTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 100
}));

const StyledIconButton = styled(IconButton)({
  '&:hover': {
    backgroundColor: 'transparent'
  },
  '&.action': {
    padding: 0,
    '& .icon': {
      width: 23,
      height: 23,

      '& svg': {
        width: 23,
        height: 23
      }
    },
    '&.btn-comments': {
      paddingLeft: 5,
      position: 'relative',
      '& .icon': {
        width: 24,
        height: 24,
  
        '& svg': {
          width: 24,
          height: 24
        }
      },
    }
  },
  '&.edit': {
    padding: 0,
    '& .icon': {
      width: 20,
      height: 20,

      '& svg': {
        width: 20,
        height: 20
      }
    }
  },
  '& .active-filters': {
    position: 'absolute',
    zIndex: 99,
    left: 7,
    top: 11,
    '& svg': {
      width: 12,
      height: 12
    }
  }
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '5px 14px',
    width: 74
  }
}));

const StyledContentCopyIcon = styled(ContentCopyIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
  width: 20
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: "#5B5959",
  fontSize: "0.875rem",
  border: 0,
  "&:before, &:after, &:hover": {
    borderBottom: 0,
  },
  "&.MuiInput-underline:hover": {
    borderBottom: 0,
  },
  "& .MuiSelect-select": {
    padding: "11.5px 14px",
  },
  "& fieldset": {
    border: 0,
  },
  "& .icon": {
    marginTop: 4,
  },
}));

const CssTable = ({ headers, totalCount, data, orderBy, order, onRequestSort, page, rowsPerPage, sx, highlightText, isDesktopFilters, from, hasCheckbox, rowClassName, rowActions, permissionCheck, onFilters, onStatusAction, onReviewStatusAction, onTablePageChange, onTableRowsPerPageChange, onRowClick, onRowCheckbox, onSelectAll, onToggleRowEditHover, onToggleCommentsHover, onEditButtonClicked, onEditKeyEnter, onEditChangeField, onLinkClick }: TableProps) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const {
    checkboxList,
    indeterminateCheck,
    selectedAllChecks,
    isFilterIconDisplay,
    enableSelectAllCheckbox,
    dashboardSelectedFilter,
    highlightNotificationRecords,
    selectedButtonType,
  } = useContext(GlobalContext);

  const getChip = (o: any, s: any, r: any) => {
    let status = _.split(_.find(s.valid, (v) => v.includes(s.processStatus ? s.processStatus.call({}, o, '', from) : o)), '|')[1];

    if (s.processData) {
      const newStatus = s.processData.call({}, r, from);

      status = newStatus || status;
    }

    if (s.type === 'oval' && s.isBlank && r.type === 'DEPOSIT' && r.paymentProviderName === 'Leantech' && r.bankAccountStatus !== '') {
      return '-';
    } else if (s.type === 'oval') {
      // PN-7746: checking onStatusAction with batchNo or without batchNo
      return <CssChip label={s.processStatus ? s.processStatus.call({}, o, '', from) : o.toLowerCase()} className={status} {...(headers.statusActions && headers.statusActions.includes(o) && onStatusAction && ((r.batchNo || !r.batchNo) || (dashboardSelectedFilter === 'DASHBOARD_COMP' && from === 'DASHBOARD') || from === 'RECON') && {onClick: () => onStatusAction(r)})} {...(headers.reviewStatusActions && headers.reviewStatusActions.includes(o) && onReviewStatusAction && {onClick: () => onReviewStatusAction(r)})} />
    } else if (s.type === 'ellipse') {
      return <StyledEllipse>
        <CssEllipse className={status} sx={{...processSx(s.key, 'ellipseStyles')}} /><StyledTypography variant='body2' className={status}>{s.processStatus ? s.processStatus.call({}, o, '', from) : o}</StyledTypography>
      </StyledEllipse>
    } else if (s.type === 'switch-icon') {
      if (status === 'ENABLED') {
        return <Eye />;
      } else {
        return <EyeClosed />
      }
    }
    
    return <StyledTypography variant='body2' className={status}>{o}</StyledTypography>;
  };

  const onCopyToClipboard = async (value: string) => {
    await navigator.clipboard.writeText(value);

    enqueueSnackbar('Copied to clipboard.', {
      variant: 'successAlert'
    });
  };

  const getProcessData = (o: any, key: string, r: any) => {
    const ignoreStatusData = _.filter(headers.statusData, (s: StatusData) => s.key === key && s.ignoreStatus)[0];

    if (o && !ignoreStatusData  && _.findIndex(headers.statusData, (s: StatusData) => s.key === key) !== -1) {
      return getChip(o, _.find(headers.statusData, (s) => s.key === key), r);
    } else if (
      o &&
      ignoreStatusData &&
      !ignoreStatusData.ignoreStatus.includes(o) &&
      _.findIndex(headers.statusData, (s: StatusData) => s.key === key) !== -1
    ) {
      return getChip(
        o,
        _.find(headers.statusData, (s) => s.key === key),
        r
      );
    } else if (
      o &&
      headers.columns &&
      headers.countryColumns &&
      headers.countryColumns.includes(key)
    ) {
      const column = _.find(headers.columns, (s) => s.key === key);
      return (
        <Box display={"flex"}>
          <CountryFlag countryCode={`${r[column.pickValuesFrom]}`} title={o} />
          <Typography sx={{ml: 2, mt: 0.2}}>{o}</Typography>
        </Box>
      );
    } else if (
      o &&
      !permissionCheck &&
      headers.columns &&
      headers.dropdownColumns &&
      headers.dropdownColumns.includes(key) &&
      _.findIndex(headers.columns, (s: any) => s.processCellData) !== -1
    ) {
      const c = _.find(
        headers.columns,
        (s) => s.key === key && s.processCellData
      );
      return c.processCellData.call({}, o, r, key);;
    } else if (
      o &&
      permissionCheck &&
      headers.columns &&
      headers.dropdownColumns &&
      headers.dropdownColumns.includes(key) &&
      headers.dropdownList
    ) {
      return (
        <StyledSelect
          onChange={(event) => rowActions.onChange.call({}, event, key, r)}
          value={r[key]}
          IconComponent={(icon) => <CheckCaret />}
        >
          {_.map(headers.dropdownList, (s: any, i: number) => (
            <MenuItem value={s.value} key={i}>
              {s.label}
            </MenuItem>
          ))}
        </StyledSelect>
      );
    } else if (
      o &&
      moment(new Date(o)).isValid() &&
      headers.dateColumns &&
      headers.dateColumns.includes(key)
    ) {
      const column = _.find(headers.columns, (s) => s.key === key);
      if (column.withTimestamp) {
        if (column.isUTC) {
          return moment(o)
            .utc()
            .format(column.format || "MM.DD.YYYY, h:mm a");
        }
        return moment(o).format(column.format || "MM.DD.YYYY, h:mm a");
      }
      return moment(o).format("MM.DD.YYYY");
    } else if (
      o &&
      headers.searchColumns &&
      headers.searchColumns.includes(key) &&
      highlightText
    ) {
      let parts;
      const patternArray = ["[", "*", "(", ")", "+", "?", "#"];
      if (
        highlightText.includes("\\") ||
        patternArray.includes(highlightText)
      ) {
        parts = o.split(highlightText);
      } else if (_.isArray(o)) {
        return (
          <>
            {_.map(o, (o) => {
              let arr = o.split(new RegExp(`(${highlightText})`, "gi"));
              return (
                <CssChip
                  label={_.map(arr, (a, i) =>
                    a.toLowerCase() === highlightText.toLowerCase() ? (
                      <b key={i}>{a}</b>
                    ) : (
                      a
                    )
                  )}
                  className={"tag-chip"}
                />
              );
            })}
          </>
        );
      } else {
        parts = o.split(new RegExp(`(${highlightText})`, "gi"));
      }

      if (
        o &&
        from !== "TRANSFERS" &&
        from !== "FINOPS" &&
        _.findIndex(headers.columns, (c: any) => c.key === key && c.isLink) !==
          -1
      ) {
        return (
          <Link
            component={"button"}
            sx={{ color: "#02B9A3", textDecoration: "none", textAlign: "left" }}
            {...(onLinkClick && { onClick: () => onLinkClick(r) })}
          >
            {parts.map((part: string, i: number) =>
              part.toLowerCase() === highlightText.toLowerCase() ? (
                <b key={i}>{part}</b>
              ) : (
                part
              )
            )}
          </Link>
        );
      } else {
        return (
          <span>
            {parts.map((part: string, i: number) =>
              part.toLowerCase() === highlightText.toLowerCase() ? (
                <b key={i}>{part}</b>
              ) : (
                part
              )
            )}
          </span>
        );
      }
    } else if (
      o &&
      headers.ellipseColumns &&
      headers.ellipseColumns.includes(key)
    ) {
      return (
        <StyledEllipseTypography variant="body2" title={o}>
          {o}
        </StyledEllipseTypography>
      );
    } else if (
      _.findIndex(
        headers.columns,
        (s: any) => s.key === key && s.isEllipseColumnWithCopy
      ) !== -1
    ) {
      if (_.findIndex(headers.columns, (s: any) => s.processCellData) !== -1) {
        const c = _.find(
          headers.columns,
          (s) => s.key === key && s.processCellData
        );
        const val = c.processCellData.call({}, o, r, key);

        return (
          <Stack alignItems="center" direction="row" gap={2}>
            <StyledEllipseTypography variant="body2" title={val}>
              {val}
            </StyledEllipseTypography>
            <IconButton onClick={() => onCopyToClipboard(val)} color="primary">
              <StyledContentCopyIcon />
            </IconButton>
          </Stack>
        );
      } else {
        return (
          <Stack alignItems="center" direction="row" gap={2}>
            <StyledEllipseTypography variant="body2" title={o}>
              {o}
            </StyledEllipseTypography>
            <IconButton onClick={() => onCopyToClipboard(o)} color="primary">
              <StyledContentCopyIcon />
            </IconButton>
          </Stack>
        );
      }
    } else if (
      o &&
      _.findIndex(
        headers.columns,
        (c: any) => c.key === key && c.pickValuesFrom
      ) !== -1
    ) {
      const column = _.find(headers.columns, (s) => s.key === key);
      return `${o} ${r[column.pickValuesFrom]}` || "-";
    } else if (
      o &&
      _.findIndex(headers.columns, (c: any) => c.key === key && c.convertTo) !==
        -1
    ) {
      const column = _.find(headers.columns, (s) => s.key === key);
      return column.convertTo || "-";
    } else if (
      headers.columns &&
      _.findIndex(
        headers.columns,
        (s: any) =>
          s.processCellData &&
          s.key === key &&
          !s.isEditableColumn &&
          !s.isEllipseColumnWithCopy
      ) !== -1
    ) {
      const c = _.find(
        headers.columns,
        (s) => s.key === key && s.processCellData
      );

      if (headers.signValueKeys && headers.signValueKeys.includes(key)) {
        if (
          (o || o === 0) &&
          _.find(headers.columns, (s) => s.key === key && s.processCellData)
        ) {
          const s = _.find(headers.columns, (s) => s.key === key);
          return (
            <span
              className={
                s.processCellData.call({}, o, r, key)
                  ? s.processCellData.call({}, o, r, key).includes("+")
                    ? "positive"
                    : "negative"
                  : ""
              }
            >
              {s.processCellData.call({}, o, r, key)}
            </span>
          );
        }
        return <span>{"-"}</span>;
      }
      return c.processCellData.call({}, o, r, key);
    } else if (
      headers.editableColumns &&
      headers.editableColumns.includes(key)
    ) {
      if (
        onToggleRowEditHover &&
        onEditButtonClicked &&
        onEditKeyEnter &&
        onEditChangeField
      ) {
        return (
          <Box
            justifyContent={"space-between"}
            sx={{ display: "flex", justifyContent: "flex-end" }}
            onMouseEnter={() => onToggleRowEditHover(r, key, true)}
            onMouseLeave={() => onToggleRowEditHover(r, key, false)}
          >
            {r[`${key}Hover`] && !r[`${key}Edit`] && (
              <StyledIconButton
                className="edit"
                onClick={() => onEditButtonClicked(r, key)}
                sx={{ position: "relative", top: "-4px", right: 60 }}
              >
                <EditPencil />
              </StyledIconButton>
            )}

            {headers.columns &&
            _.findIndex(
              headers.columns,
              (s: any) => s.processCellData && s.key === key
            ) !== -1 ? (
              <>
                {r[`${key}Edit`] ? (
                  <StyledTextField
                    type={"text"}
                    value={o}
                    onKeyPress={(event) => onEditKeyEnter(event, r, key)}
                    onChange={(event) => onEditChangeField(event, r, key)}
                  />
                ) : headers.signValueKeys &&
                  headers.signValueKeys.includes(key) ? (
                  (o || o === 0) &&
                  _.find(
                    headers.columns,
                    (s) => s.key === key && s.processCellData
                  ) ? (
                    <span
                      className={
                        _.find(
                          headers.columns,
                          (s) => s.key === key
                        ).processCellData.call({}, o, r, key)
                          ? _.find(headers.columns, (s) => s.key === key)
                              .processCellData.call({}, o, r, key)
                              .includes("+")
                            ? "positive"
                            : "negative"
                          : ""
                      }
                    >
                      {_.find(
                        headers.columns,
                        (s) => s.key === key
                      ).processCellData.call({}, o, r, key)}
                    </span>
                  ) : (
                    <span>{"$0"}</span>
                  )
                ) : (
                  <span>
                    {((o || o === 0) &&
                      _.find(
                        headers.columns,
                        (s) => s.key === key && s.processCellData
                      ).processCellData.call({}, o, r, key)) ||
                      "-"}
                  </span>
                )}
              </>
            ) : (
              <>
                {r[`${key}Edit`] ? (
                  <StyledTextField
                    type={"text"}
                    value={o}
                    onKeyPress={(event) => onEditKeyEnter(event, r, key)}
                    onChange={(event) => onEditChangeField(event, r, key)}
                  />
                ) : (
                  <span>{o || "-"}</span>
                )}
              </>
            )}
          </Box>
        );
      } else {
        return (
          <span>
            {((o || o === 0) &&
              _.find(
                headers.columns,
                (s) => s.key === key && s.processCellData
              ).processCellData.call({}, o, r, key)) ||
              "-"}
          </span>
        );
      }
    } else if (
      o &&
      headers.columns &&
      _.findIndex(headers.columns, (s: any) => s.isArray && s.key === key) !==
        -1
    ) {
      return (
        <>
          {o.length ? (
            <>
              {_.map(o, (l) => (
                <CssChip label={l} className={"tag-chip"} />
              ))}
            </>
          ) : (
            "-"
          )}
        </>
      );
    } else if (
      o &&
      from !== "TRANSFERS" &&
      from !== "FINOPS" &&
      _.findIndex(headers.columns, (c: any) => c.key === key && c.isLink) !== -1
    ) {
      return (
        <Link
          component={"button"}
          sx={{ color: "#02B9A3", textDecoration: "none", textAlign: "left" }}
          {...(onLinkClick && { onClick: () => onLinkClick(r) })}
        >
          {o}
        </Link>
      );
    }

    return <span>{o || '-'}</span>;
  };

  const createSortHandler =
    (property: any) => (event: MouseEvent<unknown>) => {
      onRequestSort && onRequestSort(event, property);
    };

  const displayMobileColumns = (key: string) => {
    if (headers.mobileColumns && _.findIndex(headers.mobileColumns, (s: any) => s.key === key) !== -1) {
      return { xs: 'table-cell', md: 'table-cell' };
    }
    return { xs: 'none', md: 'table-cell' };
  };

  const processSx = (key: string, styles: string) => {
    if (isMobile && headers.mobileColumns && _.findIndex(headers.mobileColumns, (s: any) => s.key === key) !== -1) {
      const o = _.find(headers.mobileColumns, (s) => s.key === key);
      return o[styles] || {};
    } else if (headers.statusData && _.findIndex(headers.statusData, (s: any) => s.key === key) !== -1) {
      const o = _.find(headers.statusData, (s) => s.key === key);
      return o[styles] || {};
    } else if (headers.columns && _.findIndex(headers.columns, (s: any) => s.key === key) !== -1) {
      const o = _.find(headers.columns, (s) => s.key === key);
      return o[styles] || {};
    }

    return {};
  };

  const processActions = (actions: any[], rowData: any) => {
    return actions.map(((a, i) => {
      if (a.key === 'DOWNLOAD') {
        return <StyledIconButton onClick={() => rowActions?.onDownloadAction(rowData)} key={i} className='action'><Download /></StyledIconButton>
      }
      if (a.key === 'COMMENT') {
        return <StyledIconButton onClick={() => rowActions?.onCommentsAction(rowData)} key={i} className={`action btn-comments`} {...(onToggleCommentsHover && {onMouseEnter: () => onToggleCommentsHover(rowData, true), onMouseLeave: () => onToggleCommentsHover(rowData, false)})}>
          {rowData.isCommentHover ? <ActiveComments /> : <Comments />}
          {rowData.hasComments && (<>
            {rowData.isCommentHover ? <CssEllipse className={'CIRCLE'} sx={{position: 'absolute', top: 2, right: 1, mr: 0}} /> : <CssEllipse className={'COMMENT_VERIFIED'} sx={{position: 'absolute', top: 2, right: 1, mr: 0}} />}
          </>)}
        </StyledIconButton>;
      }
      return null;
    }));
  };

  return (
    <>
      <StyledTableContainer sx={sx}>
        <Table>
          <TableHead>
            <TableRow>
              {from !== "TRANSFERS" &&
                from !== "TRANSFERS_MAIN" &&
                enableSelectAllCheckbox && (
                  <StyledCell sx={{ pl: 0 }}>
                    <CssCheckbox
                      sx={{ p: 0, height: 24, top: "-5px" }}
                      onChange={onSelectAll}
                      {...(indeterminateCheck && {
                        indeterminate: indeterminateCheck,
                      })}
                      checked={selectedAllChecks}
                    />
                  </StyledCell>
                )}

              {!enableSelectAllCheckbox && hasCheckbox && (
                <StyledCell sx={{ pl: 0 }}></StyledCell>
              )}

              {headers &&
                headers.columns &&
                headers.columns.map((o: HeadCell, index: number) => (
                  <>
                    {selectedButtonType === "EQUITY" &&
                    o.key === "netPosition" ? null : (
                      <StyledCell
                        size="small"
                        sortDirection={orderBy === o.key ? order : false}
                        key={index}
                        sx={{
                          display: displayMobileColumns(o.key),
                          ...processSx(o.key, "labelCellStyles"),
                        }}
                        {...(headers.actions &&
                          headers.columns.length - 1 === index && {
                            colSpan: 2,
                          })}
                      >
                        {headers.sortableColumns &&
                        headers.sortableColumns.includes(o.key) ? (
                          <>
                            <TableSortLabel
                              active={true}
                              direction={orderBy === o.key ? order : "asc"}
                              onClick={createSortHandler(o.key)}
                              IconComponent={(icon) => (
                                <Caret
                                  className={icon.className}
                                  fill={"#5B5959"}
                                />
                              )}
                              sx={{ ...processSx(o.key, "sortLabelStyles") }}
                            >
                              <StyledLabelTypography
                                variant="body2"
                                sx={{ ...processSx(o.key, "labelStyles") }}
                              >
                                {o.label}
                                {o.info && <InfoIcon />}
                              </StyledLabelTypography>
                            </TableSortLabel>
                          </>
                        ) : (
                          <StyledLabelTypography
                            variant="body2"
                            sx={{ ...processSx(o.key, "labelStyles") }}
                          >
                            {o.label}
                            {o.info && <InfoIcon />}
                          </StyledLabelTypography>
                        )}
                      </StyledCell>
                    )}
                  </>
                ))}

              {isDesktopFilters && (
                <StyledCell
                  {...(headers.filterAlign && { align: headers.filterAlign })}
                >
                  <StyledIconButton onClick={onFilters} aria-label="filters">
                    {isFilterIconDisplay && (
                      <Plus className={"active-filters"} />
                    )}
                    <Filters />
                  </StyledIconButton>
                </StyledCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              order &&
              orderBy &&
              stableSort(data, getComparator(order, orderBy)).map(
                (r: any, index: number) => (
                  <StyledRow
                    key={index}
                    {...(onRowClick && {
                      onClick: (event) => onRowClick(event, r),
                    })}
                    {...((highlightNotificationRecords || rowClassName) && {
                      className: `${
                        highlightNotificationRecords
                          ? "active-notification-records"
                          : ""
                      } ${rowClassName}`,
                    })}
                  >
                    {from !== "TRANSFERS" &&
                      r.type === "WITHDRAW" &&
                      r.batchNo &&
                      hasCheckbox &&
                      (r.status === "QUEUED" ||
                        r.status === "WITHDRAWAL_REQUESTED") && (
                        <TableCell sx={{ pl: "0 !important" }}>
                          <CssCheckbox
                            sx={{ p: 0, height: 24 }}
                            value={`${r.status}`}
                            onChange={onRowCheckbox}
                            name={r.status}
                            id={r.txnNumber}
                            checked={r.isChecked ?? false}
                            disabled={r.isDisabled ?? false}
                          />
                        </TableCell>
                      )}

                    {(from === "REFERRAL_GIFTS" || from === "FINOPS") &&
                      hasCheckbox &&
                      r.status === "PENDING" && (
                        <TableCell sx={{ pl: "0 !important" }}>
                          <CssCheckbox
                            sx={{ p: 0, height: 24 }}
                            value={`${r.status}`}
                            onChange={onRowCheckbox}
                            name={r.status}
                            id={r.rewardId}
                            checked={r.isChecked ?? false}
                            disabled={r.isDisabled ?? false}
                          />
                        </TableCell>
                      )}

                    {checkboxList &&
                      checkboxList.length > 0 &&
                      hasCheckbox &&
                      !r.batchNo &&
                      from !== "REFERRAL_GIFTS" &&
                      from !== "FINOPS" && (
                        <TableCell sx={{ pl: "0 !important" }}></TableCell>
                      )}

                    {checkboxList &&
                      checkboxList.length > 0 &&
                      r.batchNo &&
                      hasCheckbox &&
                      r.status !== "QUEUED" &&
                      r.status !== "WITHDRAWAL_REQUESTED" && (
                        <TableCell sx={{ pl: "0 !important" }}></TableCell>
                      )}

                    {checkboxList &&
                      checkboxList.length > 0 &&
                      (from === "REFERRAL_GIFTS" || from === "FINOPS") &&
                      hasCheckbox &&
                      !r.batchNo &&
                      r.status !== "PENDING" && (
                        <TableCell sx={{ pl: "0 !important" }}></TableCell>
                      )}

                    {headers.columns.map((h: any, i: number) => (
                      <>
                        {selectedButtonType === "EQUITY" &&
                        h.key === "netPosition" ? null : (
                          <TableCell
                            key={i}
                            sx={{
                              display: displayMobileColumns(h.key),
                              ...processSx(h.key, "dataCellStyles"),
                            }}
                          >
                            {getProcessData(r[h.key], h.key, r)}
                          </TableCell>
                        )}
                      </>
                    ))}

                    {isDesktopFilters && <TableCell />}

                    {headers.actions &&
                      permissionCheck &&
                      !rowActions?.ignoreView && (
                        <TableCell
                          {...(headers.actionCellStyles && {
                            sx: headers.actionCellStyles,
                          })}
                        >
                          {processActions(headers.actions, r)}
                        </TableCell>
                      )}
                    {headers.actions &&
                      !permissionCheck &&
                      rowActions?.ignoreView && (
                        <TableCell
                          {...(headers.actionCellStyles && {
                            sx: headers.actionCellStyles,
                          })}
                        >
                          {processActions(headers.actions, r)}
                        </TableCell>
                      )}
                    {headers.actions &&
                      permissionCheck &&
                      rowActions?.ignoreView && (
                        <TableCell
                          {...(headers.actionCellStyles && {
                            sx: headers.actionCellStyles,
                          })}
                        >
                          {processActions(headers.actions, r)}
                        </TableCell>
                      )}
                    {headers.actions && !permissionCheck && (
                      <TableCell
                        {...(headers.actionCellStyles && {
                          sx: headers.actionCellStyles,
                        })}
                      />
                    )}
                  </StyledRow>
                )
              )}

            {data &&
              data.length > 0 &&
              !order &&
              !orderBy &&
              data.map((r: any, index: number) => (
                <StyledRow
                  key={index}
                  {...(onRowClick && {
                    onClick: (event) => onRowClick(event, r),
                  })}
                >
                  {headers.columns.map((h: any, i: number) => (
                    <>
                      {selectedButtonType === "EQUITY" &&
                      h.key === "netPosition" ? null : (
                        <TableCell
                          key={i}
                          sx={{
                            display: displayMobileColumns(h.key),
                            ...processSx(h.key, "dataCellStyles"),
                          }}
                        >
                          {getProcessData(r[h.key], h.key, r)}
                        </TableCell>
                      )}
                    </>
                  ))}
                  {isDesktopFilters && <TableCell />}
                </StyledRow>
              ))}

            {data && !data.length && (
              <TableRow>
                <TableCell
                  colSpan={
                    isMobile
                      ? headers.mobileColumns.length
                      : isDesktopFilters
                      ? headers.columns.length + 1
                      : headers.columns.length
                  }
                  align="center"
                >
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <StyledTablePagination
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          className: "rows-per-page",
        }}
        onPageChange={onTablePageChange}
        onRowsPerPageChange={onTableRowsPerPageChange}
        ActionsComponent={Pagination}
      />
    </>
  );
};

export default CssTable;